import React from 'react';
import { ExternalLink, ArrowUpRight } from 'lucide-react';

const projects = [
  {
    title: "Apex Corporate Retreat",
    url: "https://apexcorporateretreat.com/",
    image: "/assets/apex-preview.jpg",
    gradient: "from-blue-600/30 to-cyan-600/30"
  },
  {
    title: "CCM",
    url: "https://relaxed-fudge-0cc0ad.netlify.app/",
    image: "/assets/fudge-preview.jpg",
    gradient: "from-purple-600/30 to-pink-600/30"
  },
  {
    title: "Theta AI",
    url: "https://theta-ai.org/",
    image: "/assets/theta-preview.jpg",
    gradient: "from-emerald-600/30 to-teal-600/30"
  },
  {
    title: "FitPal AI",
    url: "https://fitpalai.fit/",
    image: "/assets/fitpal-preview.jpg",
    gradient: "from-rose-600/30 to-orange-600/30"
  },
  {
    title: "TranscendenceX",
    url: "https://transcendencex.org/",
    image: "/assets/transcendencex-preview.jpg",
    gradient: "from-amber-600/30 to-yellow-600/30"
  }
];

export default function Projects() {
  return (
    <div className="min-h-screen relative pt-24 pb-20">
      <div className="max-w-[90%] mx-auto">
        {/* Header */}
        <div className="mb-16 space-y-3 text-center">
          <p className="text-sm text-purple-400/90 uppercase tracking-wider font-medium
            animate-fadeIn">
            Portfolio
          </p>
          <h1 className="text-3xl md:text-4xl font-bold text-transparent bg-clip-text
            bg-gradient-to-r from-purple-400 via-pink-400 to-blue-400 pb-2
            animate-fadeIn animation-delay-150">
            A Sample of my Work
          </h1>
        </div>

        {/* Projects Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6
          animate-fadeIn animation-delay-300">
          {projects.map((project, index) => (
            <a
              key={index}
              href={project.url}
              target="_blank"
              rel="noopener noreferrer"
              className="group relative bg-white/5 rounded-xl overflow-hidden
                transform hover:scale-[1.02] transition-all duration-500 ease-out
                hover:shadow-2xl hover:shadow-purple-500/10
                border border-white/5"
            >
              {/* Card Content */}
              <div className="relative aspect-[4/3]">
                {/* Background Image */}
                <img
                  src={project.image}
                  alt={project.title}
                  className="w-full h-full object-cover transform transition-transform
                    duration-700 ease-out group-hover:scale-110"
                />

                {/* Overlay Gradient */}
                <div className={`absolute inset-0 bg-gradient-to-br ${project.gradient}
                  opacity-0 group-hover:opacity-100 transition-opacity duration-500
                  backdrop-blur-sm`}
                />

                {/* Content Overlay */}
                <div className="absolute inset-0 flex flex-col justify-end p-6
                  bg-gradient-to-t from-black/80 via-black/50 to-transparent
                  opacity-0 group-hover:opacity-100 transition-opacity duration-500">

                  {/* Title */}
                  <h3 className="text-lg font-semibold text-white mb-2 transform
                    translate-y-4 group-hover:translate-y-0 transition-transform
                    duration-500 ease-out">
                    {project.title}
                  </h3>

                  {/* Visit Button */}
                  <div className="flex items-center gap-2 text-sm text-white/90
                    transform translate-y-4 opacity-0 group-hover:translate-y-0
                    group-hover:opacity-100 transition-all duration-500 ease-out">
                    <span className="font-medium">Visit Website</span>
                    <ArrowUpRight size={16} className="transform group-hover:translate-x-1
                      group-hover:-translate-y-1 transition-transform duration-300" />
                  </div>
                </div>

                {/* Hover Border Effect */}
                <div className="absolute inset-0 border-2 border-white/0
                  group-hover:border-white/20 transition-colors duration-500
                  rounded-xl pointer-events-none" />

                {/* Corner Decoration */}
                <div className="absolute -top-20 -right-20 w-40 h-40 bg-white/10
                  rounded-full blur-xl group-hover:top-0 group-hover:right-0
                  transition-all duration-700 ease-out opacity-0
                  group-hover:opacity-20" />
              </div>
            </a>
          ))}

          {/* Placeholder Card for Future Projects */}
          <div className="relative bg-white/5 rounded-xl overflow-hidden group
            hover:shadow-2xl hover:shadow-purple-500/10 transition-all duration-500
            animate-fadeIn animation-delay-450 border border-white/5">
            <div className="aspect-[4/3] relative">
              <div className="absolute inset-0 bg-gradient-to-br from-white/5 to-white/10" />

              {/* Coming Soon Content */}
              <div className="absolute inset-0 flex flex-col items-center justify-center p-6">
                <p className="text-white/50 text-lg font-medium mb-2">More Coming Soon</p>
                <div className="flex space-x-2">
                  <span className="w-2 h-2 bg-white/30 rounded-full animate-bounce"
                        style={{ animationDelay: '0ms' }} />
                  <span className="w-2 h-2 bg-white/30 rounded-full animate-bounce"
                        style={{ animationDelay: '150ms' }} />
                  <span className="w-2 h-2 bg-white/30 rounded-full animate-bounce"
                        style={{ animationDelay: '300ms' }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
