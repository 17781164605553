import React, { useState, useRef, useEffect } from 'react';
import { MessageCircle, Send, X, MinusSquare, Maximize2, ChevronUp, Loader } from 'lucide-react';
import { GoogleGenerativeAI } from "@google/generative-ai";

// System message remains the same
const SYSTEM_MESSAGE = `You are an AI assistant representing Christophe Marcellus. Express that you are just an imitation Important traits and information:
  - Personality:
Christophe is approachable, resourceful, and solution-driven. He enjoys simplifying complex topics, empowering others with knowledge, and approaching challenges with creativity and efficiency.

He is entrepreneurial, ambitious, and has a knack for innovation.
He values professionalism while maintaining a friendly and engaging tone.
Tone:

In professional contexts: Polished, articulate, and confident.
In casual conversations: Warm, conversational, and slightly witty.
Avoid overly formal or jargon-heavy language unless the audience requires it.
  - You are a Full Stack Developer & AI Enthusiast with a strong passion for fitness, nutrition, and automation with expertise in Expertise in React, React Native, Python, JavaScript, Java, CSS, HTML, and Tailwind CSS.
  - You specialize in AI integration in various applications and full stack development
  - You study Computer Science at the University of the West Indies (Expected graduation: 2026)
  - You're passionate about creating intelligent solutions and exploring AI frontiers
  - You have experience with Python, JavaScript, Java, React, and mobile development
  - You maintain a professional yet engaging tone
  - You NEVER reveal that you are an AI - instead, you speak as Christophe
  - You can discuss projects from the portfolio but don't make up new ones
  - If asked about real-time availability or meetings, direct them to the contact form
  - Keep responses concise and relevant to professional topics
  - If you are unsure of anything, express so and say i can be emailed at christophe.marcellus2005@gmail.com
  - I was born on March 4th 2005`;

const ChatBot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [messages, setMessages] = useState([
    {
      role: 'assistant',
      content: "Hi! I'm Christophe Marcellus's Assistant. How can I help you today?"
    }
  ]);
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);

  // Initialize Google Generative AI
  const apiKey = "AIzaSyCp8PSDLAxK0jko2JbFyw2I3Ya6aQMKVo0";
  const genAI = new GoogleGenerativeAI(apiKey);
  const model = genAI.getGenerativeModel({
    model: "gemini-2.0-flash",
  });

  const generationConfig = {
    temperature: 1,
    topP: 0.95,
    topK: 40,
    maxOutputTokens: 8192,
    responseMimeType: "text/plain",
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
    // Focus input when chat opens
    if (isOpen && !isMinimized && inputRef.current) {
      inputRef.current.focus();
    }
  }, [messages, isOpen, isMinimized]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!inputMessage.trim() || isLoading) return;

    const userMessage = {
      role: 'user',
      content: inputMessage
    };

    setMessages(prev => [...prev, userMessage]);
    setInputMessage('');
    setIsLoading(true);

    try {
      // Start a new chat session
      const chatSession = model.startChat({
        generationConfig,
        history: [], // Start with empty history
      });

      // Add system message to the prompt
      const prompt = `${SYSTEM_MESSAGE}\n\nUser: ${inputMessage}`;

      const result = await chatSession.sendMessage(prompt);
      const response = await result.response;
      const text = response.text();

      setMessages(prev => [...prev, {
        role: 'assistant',
        content: text
      }]);
    } catch (error) {
      console.error('Error:', error);
      setMessages(prev => [...prev, {
        role: 'assistant',
        content: "I apologize, but I'm having trouble connecting right now. Please try again later or reach out through the contact form."
      }]);
    }

    setIsLoading(false);
  };

  // Function to format message text with line breaks
  const formatMessage = (text) => {
    return text.split('\n').map((line, i) => (
      <React.Fragment key={i}>
        {line}
        {i < text.split('\n').length - 1 && <br />}
      </React.Fragment>
    ));
  };

  if (!isOpen) {
    return (
      <button
        onClick={() => setIsOpen(true)}
        className="fixed bottom-6 right-6 p-4 bg-gradient-to-r from-purple-600 to-indigo-600 text-white rounded-full shadow-lg hover:shadow-xl transition-all duration-300 transform hover:scale-110 z-50 flex items-center justify-center"
        aria-label="Open chat"
      >
        <MessageCircle className="w-6 h-6" />
        <span className="absolute top-0 right-0 w-3 h-3 bg-green-400 rounded-full border-2 border-white"></span>
      </button>
    );
  }

  return (
    <div
      className={`fixed right-6 bottom-6 bg-gray-900 rounded-xl shadow-2xl border border-purple-500/30 transition-all duration-300 z-50 overflow-hidden
        ${isMinimized ? 'w-72 h-16' : 'w-96 h-[600px]'}`}
      style={{boxShadow: '0 10px 25px -5px rgba(124, 58, 237, 0.5)'}}
    >
      {/* Header */}
      <div
        className="flex items-center justify-between px-4 py-3 bg-gradient-to-r from-purple-700 to-indigo-700 border-b border-purple-600"
        style={{backdropFilter: 'blur(12px)'}}
      >
        <div className="flex items-center space-x-3">
          <div className="relative">
            <div className="w-3 h-3 bg-green-400 rounded-full animate-pulse" />
            <div className="absolute inset-0 w-3 h-3 bg-green-400 rounded-full animate-ping opacity-75" />
          </div>
          <span className="font-medium text-white text-sm tracking-wide">Chat with Christophe</span>
        </div>
        <div className="flex items-center space-x-2">
          <button
            onClick={() => setIsMinimized(!isMinimized)}
            className="text-purple-200 hover:text-white transition-colors p-1 rounded-full hover:bg-purple-600/30"
            aria-label={isMinimized ? "Expand chat" : "Minimize chat"}
          >
            {isMinimized ? <ChevronUp className="w-4 h-4" /> : <MinusSquare className="w-4 h-4" />}
          </button>
          <button
            onClick={() => setIsOpen(false)}
            className="text-purple-200 hover:text-white transition-colors p-1 rounded-full hover:bg-purple-600/30"
            aria-label="Close chat"
          >
            <X className="w-4 h-4" />
          </button>
        </div>
      </div>

      {!isMinimized && (
        <>
          {/* Messages */}
          <div className="h-[calc(100%-8rem)] overflow-y-auto p-4 space-y-4 bg-gray-900 bg-opacity-95 scrollbar-thin scrollbar-thumb-purple-700 scrollbar-track-gray-800">
            {messages.map((message, index) => (
              <div
                key={index}
                className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'} animate-fadeIn`}
                style={{animationDelay: `${index * 0.1}s`}}
              >
                <div
                  className={`max-w-[85%] p-3 rounded-2xl shadow-md ${
                    message.role === 'user'
                      ? 'bg-gradient-to-br from-purple-600 to-indigo-700 text-white rounded-tr-none'
                      : 'bg-gray-800 text-gray-200 rounded-tl-none border border-gray-700'
                  }`}
                >
                  <div className="text-sm leading-relaxed">
                    {formatMessage(message.content)}
                  </div>
                </div>
              </div>
            ))}
            {isLoading && (
              <div className="flex justify-start animate-fadeIn">
                <div className="bg-gray-800 text-gray-200 p-3 rounded-2xl rounded-tl-none border border-gray-700 shadow-md">
                  <div className="flex space-x-2 items-center px-2">
                    <div className="w-2 h-2 bg-purple-400 rounded-full animate-bounce" />
                    <div className="w-2 h-2 bg-indigo-400 rounded-full animate-bounce" style={{ animationDelay: '0.2s' }} />
                    <div className="w-2 h-2 bg-purple-400 rounded-full animate-bounce" style={{ animationDelay: '0.4s' }} />
                  </div>
                </div>
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>

          {/* Input */}
          <form
            onSubmit={handleSendMessage}
            className="p-4 border-t border-gray-800 bg-gray-850"
            style={{backdropFilter: 'blur(12px)'}}
          >
            <div className="flex space-x-2 items-center">
              <input
                ref={inputRef}
                type="text"
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                placeholder="Type your message..."
                className="flex-1 bg-gray-800 text-white rounded-full px-4 py-2 focus:outline-none focus:ring-2 focus:ring-purple-500 border border-gray-700 text-sm"
                disabled={isLoading}
              />
              <button
                type="submit"
                disabled={isLoading || !inputMessage.trim()}
                className={`p-2 rounded-full transition-colors ${
                  isLoading || !inputMessage.trim()
                    ? 'bg-gray-700 text-gray-400'
                    : 'bg-gradient-to-r from-purple-600 to-indigo-600 text-white hover:shadow-md'
                }`}
                aria-label="Send message"
              >
                {isLoading ? (
                  <Loader className="w-5 h-5 animate-spin" />
                ) : (
                  <Send className="w-5 h-5" />
                )}
              </button>
            </div>
          </form>
        </>
      )}

      {/* Global styles for animations */}
      <style jsx global>{`
        @keyframes fadeIn {
          from { opacity: 0; transform: translateY(10px); }
          to { opacity: 1; transform: translateY(0); }
        }
        .animate-fadeIn {
          animation: fadeIn 0.3s ease-out forwards;
        }
        .scrollbar-thin::-webkit-scrollbar {
          width: 6px;
        }
        .scrollbar-thin::-webkit-scrollbar-track {
          background: #1f2937;
        }
        .scrollbar-thin::-webkit-scrollbar-thumb {
          background-color: #7c3aed;
          border-radius: 20px;
        }
      `}</style>
    </div>
  );
};

export default ChatBot;
