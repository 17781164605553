import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  Brain,
  Code,
  Monitor,
  Wrench,
  ArrowRight,
  Cpu,
  Globe,
  Smartphone,
  Bot,
  Database,
  Rocket,
  Sparkles,
  MessageCircle,
  ArrowUpRight
} from 'lucide-react';
import { debounce } from 'lodash';

const skills = [
  { name: 'AI', icon: <Brain className="w-5 h-5" /> },
  { name: 'IT', icon: <Monitor className="w-5 h-5" /> },
  { name: 'Web Development', icon: <Code className="w-5 h-5" /> },
  { name: 'Python', icon: <Code className="w-5 h-5" /> },
  { name: 'React', icon: <Code className="w-5 h-5" /> },
  { name: 'Java', icon: <Code className="w-5 h-5" /> },
  { name: 'Problem Solving', icon: <Wrench className="w-5 h-5" /> },
  { name: 'Data Analysis', icon: <Brain className="w-5 h-5" /> },
  { name: 'Cloud Computing', icon: <Monitor className="w-5 h-5" /> },
  { name: 'JavaScript', icon: <Code className="w-5 h-5" /> },
  { name: 'Mobile Development', icon: <Smartphone className="w-5 h-5" /> },
];

const projectSummaries = [
  {
    title: "Local LLM Development",
    description: "Building and fine-tuning Large Language Models locally for specialized tasks. Creating custom AI solutions that run efficiently on local hardware.",
    icon: <Cpu className="w-8 h-8" />,
    gradient: "from-blue-500/20 to-cyan-500/20",
    hoverGradient: "from-blue-600/30 to-cyan-600/30"
  },
  {
    title: "AI-Powered Web Apps",
    description: "Developing intelligent web applications that leverage AI for enhanced user experiences, from smart content generation to predictive interfaces.",
    icon: <Globe className="w-8 h-8" />,
    gradient: "from-purple-500/20 to-pink-500/20",
    hoverGradient: "from-purple-600/30 to-pink-600/30"
  },
  {
    title: "Intelligent Automation",
    description: "Creating smart automation systems and bots for lead generation, data scraping, and process optimization. Making businesses more efficient through AI.",
    icon: <Bot className="w-8 h-8" />,
    gradient: "from-green-500/20 to-emerald-500/20",
    hoverGradient: "from-green-600/30 to-emerald-600/30"
  },
  {
    title: "AI Mobile Applications",
    description: "Building mobile apps that integrate AI capabilities for real-time processing, computer vision, and natural language understanding.",
    icon: <Smartphone className="w-8 h-8" />,
    gradient: "from-yellow-500/20 to-orange-500/20",
    hoverGradient: "from-yellow-600/30 to-orange-600/30"
  },
  {
    title: "Data & AI Integration",
    description: "Implementing AI-driven data solutions that transform raw information into actionable insights through advanced analytics and machine learning.",
    icon: <Database className="w-8 h-8" />,
    gradient: "from-red-500/20 to-rose-500/20",
    hoverGradient: "from-red-600/30 to-rose-600/30"
  },
  {
    title: "Experimental AI Projects",
    description: "Exploring cutting-edge AI technologies and creating innovative solutions that push the boundaries of what's possible with artificial intelligence.",
    icon: <Rocket className="w-8 h-8" />,
    gradient: "from-indigo-500/20 to-violet-500/20",
    hoverGradient: "from-indigo-600/30 to-violet-600/30"
  }
];

const featuredProjects = [
  {
    title: "Law Society of Singapore",
    subtitle: "Mobile Apps & AI Assistant",
    description: "Developed comprehensive AI solution for the Law Society of Singapore, serving over 6,000 legal professionals. The iOS and Android applications provide seamless access to legal resources, CPD tracking, and member services. The custom-built AI chatbot processes over numerous legal documents, precedents, and practice guidelines, offering instant, accurate responses to legal queries. The system employs advanced NLP to understand complex legal terminology and context, providing relevant case citations and practice directions. The mobile apps feature real-time notifications for legal updates, event management, and secure member-to-member communication. Integration with the Society's backend systems ensures up-to-date access to member services, CPD records, and practice resources. The AI assistant utilizes custom-trained models on Singapore law, capable of understanding both English and Singlish queries while maintaining strict confidentiality protocols.",
    image: "/assets/law-society-preview.jpg",
    gradient: "from-blue-600/30 to-indigo-600/30",
    link: "https://www.lawsociety.org.sg",
    tech: ["React Native", "iOS", "Android", "AI/ML", "NLP", "Firebase", "Node.js", "Python"],
    features: [
      "iOS & Android applications",
      "AI-powered legal resource chatbot",
      "Member-exclusive access",
      "Real-time updates",
      "CPD tracking system",
      "Secure communication platform"
    ],
    appLinks: [
      {
        title: "iOS App",
        image: "/assets/ios-app-preview.jpg",
        url: "https://apps.apple.com/in/app/law-society-of-singapore/id6739907329?uo=2"
      },
      {
        title: "Android App",
        image: "/assets/android-app-preview.jpg",
        url: "https://play.google.com/store/apps/details?id=com.lawsoc.app&hl=en_SG"
      },
      {
        title: "Legal Assistant",
        image: "/assets/legal-assistant-preview.jpg",
        url: "https://www.lawsociety.org.sg/"
      }
    ]
  },
  {
    title: "FitPal AI",
    subtitle: "Your AI-Powered Fitness Companion",
    description: "A cutting-edge fitness application that revolutionizes personal health management through advanced AI technology. FitPal AI uses computer vision to analyze meals in real-time, providing instant nutritional insights and recommendations. The app features a sophisticated machine learning model that adapts to your eating patterns and fitness goals, offering personalized meal suggestions and workout plans. Our AI coach, available 24/7, combines nutritional science with machine learning to provide contextual advice, meal alternatives, and motivation. The platform includes advanced progress tracking with interactive charts and predictive analytics to help users understand their fitness journey better.",
    image: "/assets/fitpal-preview.jpg",
    gradient: "from-emerald-600/30 to-teal-600/30",
    link: "https://fitpalai.fit/",
    tech: ["AI Vision", "React Native", "Cloud Computing", "NLP", "Machine Learning", "Appwrite"],
    features: [
      "Real-time meal analysis with AI vision",
      "Detailed nutrient breakdown",
      "Interactive progress tracking",
      "24/7 AI nutritionist access",
      "Personalized meal planning",
      "Advanced fitness analytics"
    ]
  },
  {
    title: "Koufu AI Query System",
    subtitle: "Intelligent SQL Query Generator",
    description: "An innovative prototype developed for Koufu, Singapore's leading food court operator, this AI system transforms natural language into precise SQL queries. The solution features a custom-trained language model that understands specific business terminology, product codes, and transaction patterns unique to Koufu's operations. The AI processes complex queries about sales trends, inventory management, and customer behavior, generating optimized SQL that integrates with their existing database architecture. The system includes advanced context awareness, capable of understanding temporal references and business-specific metrics, while maintaining strict data security protocols. While the full implementation details are protected by NDA, this prototype demonstrates the potential of AI in simplifying database interactions for business users, enabling non-technical staff to extract valuable insights through natural language queries.",
    image: "/assets/koufu-preview.jpg",
    gradient: "from-purple-600/30 to-pink-600/30",
    link: "https://statuesque-pegasus-e979cb.netlify.app/",
    tech: ["AI/ML", "Natural Language Processing", "SQL", "Python", "FastAPI", "PostgreSQL"],
    features: [
      "Natural language to SQL conversion",
      "Custom-trained AI model",
      "Context-aware query generation",
      "Secure data handling",
      "Business intelligence integration",
      "Real-time query optimization"
    ]
  }
];

const AIChatPrompt = ({ onClose }) => {
  return (
    <div className="fixed bottom-24 right-4 bg-gradient-to-r from-purple-600/90 to-pink-600/90
      rounded-lg shadow-xl p-4 max-w-xs animate-float z-50 backdrop-blur-sm border border-white/10">
      <button
        onClick={onClose}
        className="absolute -top-2 -right-2 bg-purple-700 rounded-full p-1 hover:bg-purple-800
          transition-colors duration-300"
      >
        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
      <div className="flex items-start space-x-3">
        <MessageCircle className="w-6 h-6 text-white/90 mt-1" />
        <div>
          <p className="text-white/90 text-sm font-medium mb-2">
            Hi there! I'm Christophe's AI assistant.
          </p>
          <p className="text-white/80 text-sm">
            Want to learn more about my work with AI and tech? Feel free to ask me anything!
          </p>
        </div>
      </div>
    </div>
  );
};

const FeaturedProjectSection = ({ project, index, isEven }) => {
  return (
    <section className={`min-h-screen flex items-center py-20 relative overflow-hidden ${
      isEven ? 'bg-black/30' : 'bg-black/10'
    }`}>
      {/* Animated Background Elements */}
      <div className="absolute inset-0 pointer-events-none">
        <div className={`absolute w-[500px] h-[500px] rounded-full blur-3xl opacity-20
          ${isEven ? 'right-0 top-0 bg-blue-500/30' : 'left-0 bottom-0 bg-purple-500/30'}
          animate-pulse`} />
        <div className="absolute inset-0 bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:24px_24px]" />
      </div>

      <div className="container mx-auto px-4 relative">
        <div className={`grid grid-cols-1 lg:grid-cols-2 gap-12 items-center ${
          isEven ? 'lg:flex-row-reverse' : ''
        }`}>
          {/* Text Content */}
          <div className="space-y-8 backdrop-blur-sm p-8 rounded-2xl bg-black/20 border border-white/10">
            <div className="space-y-4">
              <h3 className="text-sm uppercase tracking-wider text-purple-400 flex items-center gap-2">
                <span className="h-px flex-1 bg-gradient-to-r from-purple-400/50 to-transparent"></span>
                Featured Project {index + 1}
                <span className="h-px flex-1 bg-gradient-to-l from-purple-400/50 to-transparent"></span>
              </h3>
              <h2 className="text-4xl md:text-5xl font-bold bg-clip-text text-transparent
                bg-gradient-to-r from-purple-400 to-pink-400 leading-tight">
                {project.title}
              </h2>
              <p className="text-xl text-gray-400">
                {project.subtitle}
              </p>
            </div>

            <p className="text-lg text-gray-300 leading-relaxed">
              {project.description}
            </p>

            <div className="flex flex-wrap gap-3">
              {project.tech.map((tech, i) => (
                <span key={i} className="px-4 py-2 rounded-full bg-white/5 text-sm text-white/80
                  border border-white/10 hover:bg-white/10 transition-colors duration-300
                  hover:border-purple-500/50">
                  {tech}
                </span>
              ))}
            </div>

            <div className="space-y-4">
              <h4 className="text-lg font-semibold text-white">Key Features</h4>
              <ul className="grid grid-cols-2 gap-4">
                {project.features.map((feature, i) => (
                  <li key={i} className="flex items-center gap-2 text-gray-300 group">
                    <span className="h-1.5 w-1.5 rounded-full bg-purple-400 group-hover:scale-150 transition-transform duration-300" />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>

            {project.appLinks && (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-8">
                {project.appLinks.map((app, i) => (
                  <a
                    key={i}
                    href={app.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="group relative overflow-hidden rounded-xl border border-white/10
                      hover:border-purple-500/50 transition-colors duration-300"
                  >
                    <img
                      src={app.image}
                      alt={app.title}
                      className="w-full aspect-[3/2] object-cover transform group-hover:scale-110
                        transition-transform duration-500"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/50 to-transparent
                      flex items-end justify-center p-4 opacity-0 group-hover:opacity-100
                      transition-opacity duration-300">
                      <span className="text-white font-medium">{app.title}</span>
                    </div>
                  </a>
                ))}
              </div>
            )}

            {/* View Project Button - Only show if not Law Society */}
            {project.title !== "Law Society of Singapore" && (
              <div>
                <a
                  href={project.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center gap-2 px-6 py-3 rounded-full
                    bg-gradient-to-r from-purple-600 to-pink-600 text-white
                    hover:from-purple-500 hover:to-pink-500 transition-all duration-300
                    transform hover:scale-105 shadow-lg shadow-purple-500/25"
                >
                  View Project
                  <ArrowUpRight size={18} className="transform group-hover:translate-x-1
                    group-hover:-translate-y-1 transition-transform duration-300" />
                </a>
              </div>
            )}
          </div>

          {/* Image */}
          <div className="relative group">
            <div className="relative rounded-xl overflow-hidden aspect-video">
              <img
                src={project.image}
                alt={project.title}
                className="w-full h-full object-cover transform group-hover:scale-105
                  transition-transform duration-700"
              />
              <div className={`absolute inset-0 bg-gradient-to-br ${project.gradient}
                opacity-40 group-hover:opacity-60 transition-opacity duration-300`}
              />
            </div>
            {/* Decorative Elements */}
            <div className="absolute -inset-4 border border-white/10 rounded-xl -z-10
              group-hover:border-purple-500/20 transition-colors duration-300" />
            <div className="absolute -inset-8 border border-white/5 rounded-xl -z-20
              group-hover:border-purple-500/10 transition-colors duration-300" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default function Home() {
  const [activeCard, setActiveCard] = useState(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isScrolled, setIsScrolled] = useState(false);
  const [showAIPrompt, setShowAIPrompt] = useState(false);
  const [activeSection, setActiveSection] = useState('hero');

  // Mouse parallax effect
  const handleMouseMove = useCallback((e) => {
    const { clientX, clientY } = e;
    const moveX = clientX - window.innerWidth / 2;
    const moveY = clientY - window.innerHeight / 2;
    setMousePosition({ x: moveX * 0.01, y: moveY * 0.01 });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);

      // Determine active section
      const sections = ['hero', 'skills', 'projects', 'featured'];
      const currentSection = sections.find(section => {
        const element = document.getElementById(section);
        if (!element) return false;
        const rect = element.getBoundingClientRect();
        return rect.top <= 100 && rect.bottom >= 100;
      });
      if (currentSection) setActiveSection(currentSection);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div
      className="min-h-screen relative overflow-hidden bg-black"
      onMouseMove={handleMouseMove}
    >
      {/* Interactive Background */}
      <div className="fixed inset-0 z-0">
        <div
          className="absolute inset-0 bg-gradient-to-br from-purple-900/20 to-blue-900/20"
          style={{
            transform: `translate(${mousePosition.x}px, ${mousePosition.y}px)`,
            transition: 'transform 0.2s ease-out'
          }}
        />
        <div className="absolute inset-0 bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:24px_24px]" />
      </div>

      {/* Animated Orbs */}
      <div className="fixed inset-0 pointer-events-none">
        <div className="absolute top-1/4 left-1/4 w-96 h-96 bg-purple-500/10 rounded-full blur-3xl animate-float" />
        <div className="absolute bottom-1/4 right-1/4 w-[30rem] h-[30rem] bg-blue-500/10 rounded-full blur-3xl animate-float-delayed" />
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[40rem] h-[40rem] bg-pink-500/10 rounded-full blur-3xl animate-pulse" />
      </div>

      {/* Main Content */}
      <div className="relative z-10">
        {/* Hero Section */}
        <section id="hero" className="min-h-screen flex items-center justify-center py-20 relative">
          <div className="container mx-auto px-4">
            <div className="max-w-6xl mx-auto space-y-8 text-center relative">
              <div className="relative inline-block">
                <div className="absolute -inset-4 bg-gradient-to-r from-purple-600/20 to-blue-600/20 rounded-full blur-xl animate-pulse" />
                <h3 className="relative px-6 py-2 text-white font-medium bg-white/5 rounded-full border border-white/10
                  backdrop-blur-sm animate-fadeIn">
                  Exploring the Future of AI
                </h3>
              </div>

              <div className="relative">
                <h1 className="text-7xl md:text-8xl font-bold mb-6 animate-fadeIn animation-delay-150">
                  <span className="text-white">Hi! I'm </span>
                  <span className="relative">
                    <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-400 via-pink-400 to-blue-400
                      animate-gradient">
                      Christophe
                    </span>
                    <div className="absolute -inset-1 bg-gradient-to-r from-purple-600/20 to-blue-600/20 blur-xl opacity-50" />
                  </span>
                </h1>
                <h2 className="text-4xl md:text-5xl text-white/90 font-light animate-fadeIn animation-delay-300
                  bg-gradient-to-r from-purple-400/80 via-pink-400/80 to-blue-400/80 text-transparent bg-clip-text">
                  Full Stack Developer & AI Enthusiast
                </h2>
              </div>

              <p className="text-xl text-gray-300 max-w-3xl mx-auto animate-fadeIn animation-delay-450 leading-relaxed
                backdrop-blur-sm p-6 rounded-2xl bg-white/5 border border-white/10">
                I craft intelligent solutions that push the boundaries of technology. From fine-tuning
                AI models to building smart applications, I turn complex challenges into innovative digital experiences.
              </p>
            </div>
          </div>

          {/* Scroll Indicator */}
          <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2 animate-bounce">
            <div className="w-6 h-10 rounded-full border-2 border-white/30 flex items-start justify-center p-2">
              <div className="w-1 h-3 bg-white/50 rounded-full animate-scroll" />
            </div>
          </div>
        </section>

        {/* Skills Section */}
        <section id="skills" className="py-32 relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-b from-black/0 via-purple-900/10 to-black/0" />
          <div className="container mx-auto px-4 relative">
            <div className="max-w-6xl mx-auto">
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-6">
                {skills.map((skill, index) => (
                  <div
                    key={index}
                    className="group relative"
                  >
                    <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-600 to-pink-600 rounded-lg blur opacity-0
                      group-hover:opacity-100 transition duration-500 animate-pulse" />
                    <div className="relative flex items-center gap-2 px-5 py-4 bg-black rounded-lg
                      backdrop-blur-xl border border-white/10 hover:border-purple-500/50
                      transition-all duration-300 transform hover:scale-105"
                    >
                      <span className="transform group-hover:rotate-12 transition-transform duration-300 text-white/80">
                        {skill.icon}
                      </span>
                      <span className="font-medium text-white/80 group-hover:text-white">{skill.name}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        {/* Project Cards */}
        <section id="projects" className="py-32 relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-b from-black/0 via-blue-900/10 to-black/0" />
          <div className="container mx-auto px-4 relative">
            <div className="max-w-6xl mx-auto">
              <h2 className="text-5xl font-bold text-center mb-20">
                <span className="relative">
                  <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-400">
                    Crafting Tomorrow's Solutions
                  </span>
                  <div className="absolute -inset-1 bg-gradient-to-r from-purple-600/20 to-pink-600/20 blur-xl opacity-50" />
                </span>
              </h2>
              <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
                {projectSummaries.map((project, index) => (
                  <div
                    key={index}
                    className="group relative"
                  >
                    <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-600 to-pink-600 rounded-xl blur opacity-0
                      group-hover:opacity-100 transition duration-500" />
                    <div className={`relative p-8 rounded-xl backdrop-blur-xl bg-black/50 border border-white/10
                      hover:border-purple-500/50 transform hover:scale-[1.02] transition-all duration-500
                      ${activeCard === index ? 'ring-2 ring-purple-500/50' : ''}`}
                      onMouseEnter={() => setActiveCard(index)}
                      onMouseLeave={() => setActiveCard(null)}
                    >
                      <div className="text-white/90 mb-6 transform transition-all duration-300
                        group-hover:scale-110 group-hover:rotate-12">
                        {project.icon}
                      </div>
                      <h3 className="text-xl font-semibold text-white mb-4 group-hover:text-transparent
                        group-hover:bg-clip-text group-hover:bg-gradient-to-r from-purple-400 to-pink-400">
                        {project.title}
                      </h3>
                      <p className="text-gray-300 leading-relaxed transform transition-all duration-300
                        group-hover:text-white">
                        {project.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        {/* Featured Projects */}
        <section id="featured" className="relative">
          <div className="absolute inset-0 bg-gradient-to-b from-black/0 via-purple-900/10 to-black/0" />
          {featuredProjects.map((project, index) => (
            <FeaturedProjectSection
              key={project.title}
              project={project}
              index={index}
              isEven={index % 2 === 0}
            />
          ))}

          {/* See More Projects Button */}
          <div className="text-center py-20 relative">
            <div className="absolute -inset-4 bg-gradient-to-r from-purple-600/20 to-pink-600/20 blur-xl opacity-50" />
            <Link
              to="/projects"
              className="relative inline-flex items-center gap-2 px-8 py-4 rounded-full
                bg-gradient-to-r from-purple-600/80 to-pink-600/80 text-white
                hover:from-purple-500 hover:to-pink-500 transition-all duration-300
                transform hover:scale-105 font-medium shadow-lg shadow-purple-500/25
                backdrop-blur-sm border border-white/10 hover:border-purple-500/50"
            >
              See More Sample Projects
              <ArrowUpRight size={20} className="transform group-hover:translate-x-1
                group-hover:-translate-y-1 transition-transform duration-300" />
            </Link>
          </div>
        </section>
      </div>

      {/* AI Chat Prompt */}
      {showAIPrompt && <AIChatPrompt onClose={() => setShowAIPrompt(false)} />}

      {/* Navigation Dots */}
      <div className="fixed right-8 top-1/2 transform -translate-y-1/2 space-y-4 z-50">
        {['hero', 'skills', 'projects', 'featured'].map((section) => (
          <a
            key={section}
            href={`#${section}`}
            className={`block w-3 h-3 rounded-full transition-all duration-300 ${
              activeSection === section
                ? 'bg-purple-500 scale-125'
                : 'bg-white/30 hover:bg-white/50'
            }`}
          />
        ))}
      </div>
    </div>
  );
}
